body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  background-color: #121212;
  color: #e0e0e0;
}

.App {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  padding: 10px;
}

.bingo-container {
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
  padding: 10px;
  text-align: center;
  background-color: #1e1e1e;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(255,255,255,0.1);
}

.bingo-title {
  color: #bb86fc;
  margin-bottom: 15px;
  font-size: 24px;
}

.bingo-board {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 5px;
  margin-bottom: 15px;
  aspect-ratio: 1;
}

.bingo-card {
  aspect-ratio: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #2e2e2e;
  border: 1px solid #3e3e3e;
  border-radius: 4px;
  cursor: pointer;
  padding: 2px;
  transition: all 0.3s ease;
  color: #e0e0e0;
  overflow: hidden;
}

.bingo-card-text {
  font-size: 14px;
  line-height: 1.2;
  word-wrap: break-word;
  text-align: center;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.bingo-card:hover {
  background-color: #3e3e3e;
}

.bingo-card.marked {
  background-color: #bb86fc;
  border-color: #9966cc;
  color: #121212;
}

.bingo-win {
  font-size: 24px;
  font-weight: bold;
  color: #bb86fc;
  margin-bottom: 15px;
}

.new-game-btn {
  padding: 10px 20px;
  font-size: 16px;
  background-color: #bb86fc;
  color: #121212;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.new-game-btn:hover {
  background-color: #9966cc;
}

@media (max-width: 480px) {
  .bingo-title {
    font-size: 20px;
  }

  .bingo-card-text {
    font-size: 12px;
  }

  .new-game-btn {
    padding: 8px 16px;
    font-size: 14px;
  }
}

@media (max-width: 320px) {
  .bingo-title {
    font-size: 18px;
  }

  .bingo-card-text {
    font-size: 10px;
  }

  .new-game-btn {
    padding: 6px 12px;
    font-size: 12px;
  }
}